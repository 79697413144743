<template>
  <v-container
    fluid
    class="pt-0 pb-0 pl-0 pr-0"
  >
    <v-banner
      v-if="showTechnicalDifficultiesNotice"
      color="orange"
      icon="mdi-alert"
      icon-color="black"
    >
      <div class="mb-2">
        Við erum að upplifa tæknilega erfiðleika í áskriftakerfi SalesCloud. Við afsökum þau vandmál sem þetta kann að valda en verið er að vinna í lausn.
      </div>

      <div>We are experience some technical difficulties with our subscriptions. We apologize for the inconvenience but we are working on a solution.</div>
    </v-banner>
    <v-tabs-items v-model="selectedAppBarTab">
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <v-alert
          v-if="!autoRenewalActive"
          type="error"
          style="margin: 0px"
        >
          {{ $t('autoRenewalDisabled') }}
        </v-alert>
        <v-row
          class="pt-0 pb-0"
          style="height: 100vh;"
        >
          <v-col
            cols="4"
            class="pt-0 pb-0 pr-0"
            style="border-right: 1px solid #ddd;"
          >
            <v-card
              tile
              flat
              max-height="100vh"
              class="overflow-y-auto"
            >
              <SubscriptionList
                v-if="tab.key === 'active'"
                @selectSubscription="selectSubscription"
                :load-items-callback="getSubscriptions"
                :fixed-filters="activeFixedFilters"
              />
              <SubscriptionList
                v-if="tab.key === 'inactive'"
                @selectSubscription="selectSubscription"
                :load-items-callback="getSubscriptions"
                :fixed-filters="inactiveFixedFilters"
              />
              <SubscriptionList
                v-if="tab.key === 'all'"
                @selectSubscription="selectSubscription"
                :load-items-callback="getSubscriptions"
              />
              <SubscriptionList
                v-if="tab.key === 'ongoing'"
                @selectSubscription="selectSubscription"
                :load-items-callback="getSubscriptions"
                :fixed-filters="ongoingFixedFilters"
              />
              <SubscriptionList
                v-if="tab.key === 'overdue'"
                @selectSubscription="selectSubscription"
                :load-items-callback="getSubscriptions"
                :fixed-filters="overdueFixedFilters"
              />
              <SubscriptionList
                v-if="tab.key === 'expired'"
                @selectSubscription="selectSubscription"
                :load-items-callback="getSubscriptions"
                :fixed-filters="expiredFixedFilters"
              />
              <SubscriptionList
                v-if="tab.key === 'paused'"
                @selectSubscription="selectSubscription"
                :load-items-callback="getSubscriptions"
                :fixed-filters="pausedFixedFilters"
              />
              <ActionList
                :filters="[]"
                :actions="[]"
                v-if="tab.key === 'paymentBatches'"
                :load-items-callback="getPaymentBatches"
                :empty-text="$t('noPaymentBatchesFound')"
              >
                <template v-slot:item="{ item, index }">
                  <PaymentBatchListItem
                    :payment-batch="item"
                    :key="index"
                    @selectPaymentBatch="selectPaymentBatch"
                  />
                  <v-divider inset />
                </template>
              </ActionList>
            </v-card>
          </v-col>
          <v-col
            cols="8"
            class="grey lighten-3"
          >
            <v-card
              v-if="selectedPaymentBatch !== null"
              max-height="100vh"
              flat
              tile
              class="pr-3 overflow-y-auto"
            >
              <PaymentBatchProfile :payment-batch="selectedPaymentBatch" />
            </v-card>
            <v-card
              v-if="selectedSubscription !== null"
              max-height="100vh"
              flat
              tile
              class="pr-3 overflow-y-auto"
            >
              <SubscriptionProfile :subscription="selectedSubscription" />
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
  import SubscriptionList from "@/components/SubscriptionList"
  import SubscriptionProfile from "@/components/subscriptions/SubscriptionProfile"
  import PaymentBatchListItem from "@/components/paymentBatch/PaymentBatchListItem"
  import ActionList from "@/components/common/list/ActionList"
  import PaymentBatchProfile from "@/components/paymentBatch/PaymentBatchProfile"

  export default {
    name: 'Subscriptions',
    components: {
      SubscriptionProfile,
      SubscriptionList,
      ActionList,
      PaymentBatchListItem,
      PaymentBatchProfile
    },
    data: () => ({
      selectedSubscription: null,
      selectedPaymentBatch: null,
      activeFixedFilters: [
        {
          key: 'active',
          value: true,
          operator: 'equals',
          type: 'boolean'
        }, 
      ],
      inactiveFixedFilters: [
        {
          key: 'active',
          value: false,
          operator: 'equals',
          type: 'boolean'
        }, 
      ],
      ongoingFixedFilters: [
        {
          key: 'active',
          value: true,
          operator: 'equals',
          type: 'boolean'
        },
        {
          key: 'paused',
          value: false,
          operator: 'equals',
          type: 'boolean'
        },
        {
          key: 'expires',
          value: new Date(),
          operator: 'after' || 'equals',
          type: 'date'
        },
        {
          key: 'nextPayment',
          value: new Date(),
          operator: 'after' || 'equals',
          type: 'date'
        }
      ],
      pausedFixedFilters: [
        {
          key: 'paused',
          value: true,
          operator: 'equals',
          type: 'boolean'
        }, 
        {
          key: 'active',
          value: true,
          operator: 'equals',
          type: 'boolean'
        }, 
        {
          key: 'expires', 
          value: new Date(), 
          operator: 'after' || 'equals', 
          type: 'date'
        },
      ], 
      expiredFixedFilters: [
        {
          key: 'expires', 
          value: new Date(), 
          operator: 'before', 
          type: 'date'
        },
      ], 
      overdueFixedFilters: [
        {
          key: 'nextPayment', 
          value: new Date(), 
          operator: 'before', 
          type: 'date'
        }, 
        {
          key: 'nextPayment', 
          value: new Date(), 
          operator: 'before', 
          type: 'date'
        },
         {
          key: 'active',
          value: true,
          operator: 'equals',
          type: 'boolean'
        }, 
        {
          key: 'expires', 
          value: new Date(), 
          operator: 'after' || 'equals', 
          type: 'date'
        },
      ], 
    }),
    watch: {
      subscriptions(newSubscriptions, oldSubscriptions) {
        if(newSubscriptions !== null && oldSubscriptions === null) {
          this.$store.commit('updateAppBarTabs', this.tabs)
        }
      },
      selectedAppBarTab() {
        this.selectedSubscription = null
      }
    },
    computed: {
		showTechnicalDifficultiesNotice() {
			return false
		},
      selectedAppBarTab() {
        return this.$store.state.selectedAppBarTab
      },
      subscriptions() {
        return this.$store.state.subscriptions
      },
      tabs() {
        return [
          {
            title: this.$t('ongoing'),
            key: 'ongoing',
            badge: {
              color: 'green',
              content: 0
            }
          },
          {
            title: this.$t('overdue'),
            key: 'overdue',
            badge: {
              color: 'orange',
              content: 0,
            }
          },
          {
            title: this.$t('paused'),
            key: 'paused',
            badge: {
              color: 'blue',
              content: 0,
            }
          },
          {
            title: this.$t('expired'),
            key: 'expired',
            badge: {
              color: 'blue',
              content: 0,
            }
          },
          {
            title: this.$t('active'),
            key: 'active',
            badge: {
              color: 'green',
              content: 0
            }
          },
          {
            title: this.$t('inactive'),
            key: 'inactive',
            badge: {
              color: 'red',
              content: 0,
            }
          },
          {
            title: this.$t('all'), 
            key: 'all',
            badge: {
              color: 'blue',
              content: 0
            }
          },
          {
            title: this.$t('paymentBatch'), 
            key: 'paymentBatches',
            badge: {
              color: 'blue',
              content: 0
            }
          }
        ]
      },
      autoRenewalActive() {
        if(this.$store.state.organization.settings && this.$store.state.organization.settings.booleans) {
           return this.$store.state.organization.settings.booleans.some(e => e.key === 'commerce_subscriptions:autorenewal:status' && e.value === true)
        }
       return false
      }
    },
    methods: {
      selectPaymentBatch(paymentBatch) {
        this.selectedPaymentBatch = paymentBatch
      },
      getPaymentBatches() {
        return this.$store.dispatch('getPaymentBatches')
      },
      getSubscriptions({limit, skip, conditions}) {
        return this.$store.dispatch('getSubscriptions', {})
      },
      exportSubscriptions() {
        this.$store.commit('updateExportObjects', this.subscriptions)
      },
      selectSubscription(subscription) {
        this.selectedSubscription = subscription
      }
    },
    updated() {
		  this.$store.commit('updateAppBarTabs', this.tabs)
    },
    created() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
      this.$store.commit('updateAppBarExport', {
        color: 'pink',
        callback: this.exportSubscriptions
      })
    }
  }
</script>
