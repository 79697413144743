<template>
  <v-list-item three-line>
    <v-list-item-avatar>
      <v-icon
        v-if="state === 'processed'"
        color="success"
      >
        mdi-check
      </v-icon>
      <v-icon
        v-if="state === 'success'"
        color="success"
      >
        mdi-check
      </v-icon>
      <v-icon
        v-if="state === 'pending'"
        color="info"
      >
        mdi-check
      </v-icon>
      <v-icon
        v-if="state === 'failure'"
        color="error"
      >
        mdi-alert
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ customerName }}
      </v-list-item-title>
      <v-list-item-subtitle>
        <span class="text--primary">{{ createdDate | moment.format("dddd, MMMM Do YYYY, h:mm:ss") }}</span>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ message }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action style="align-self: center;">
      <v-list-item-action-text>{{ charge | price }}</v-list-item-action-text>
    </v-list-item-action>
    <v-list-item-action style="align-self: center;">
      <v-btn
        :loading="processing"
        icon
        @click="process"
        :disabled="paymentBatchItem.state === 'processed'"
      >
        <v-icon>mdi-autorenew</v-icon>
      </v-btn>
    </v-list-item-action>
    <v-list-item-action style="align-self: center;">
      <v-btn
        :loading="cancelling"
        icon
        @click="cancel"
        :disabled="paymentBatchItem.state !== 'pending'"
      >
        <v-icon>mdi-cancel</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>

export default {
	name: 'PaymentBatchItem',
	props: {
		paymentBatchItem: {
			type: Object,
			required: true,
			default: null
		}
	},
	data() {
		return {
			cancelling: false,
			processing: false
		}
	},
	methods: {
		cancel() {
			this.cancelling = true
			this.$store.dispatch('cancelPaymentBatchItem', this.paymentBatchItem.uuid).finally(() => {
				this.cancelling = false
			})
		},
		process() {
			this.processing = true
			this.$store.dispatch('processPaymentBatchItem', this.paymentBatchItem.uuid).finally(() => {
				this.processing = false
			})
		}
	},
	computed: {
		createdDate() {
			return new Date(this.paymentBatchItem.created)
		},
		customerName() {
			return this.paymentBatchItem.customer.address.name_line
		},
		charge() {

			if (this.paymentBatchItem.payment !== null && this.paymentBatchItem.payment !== undefined) {
				return {
					amount: this.paymentBatchItem.payment.amount,
					currency_code: this.paymentBatchItem.payment.currency_code
				}
			}

			if (this.paymentBatchItem.order !== null && this.paymentBatchItem.order !== undefined) {
				return this.paymentBatchItem.order.balance
			}

			return null
		},
		message() {

			if (this.paymentBatchItem.payment === null || this.paymentBatchItem.payment === undefined) {
				return this.$t('noPaymentCouldBeGenerated')
			}

			return this.paymentBatchItem.payment.message
		},
		state() {

			if (this.paymentBatchItem.payment !== null && this.paymentBatchItem.payment !== undefined) {
				return this.paymentBatchItem.payment.status
			}

			return this.paymentBatchItem.state
		}
	},
}
</script>
