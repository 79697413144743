<template>
  <div>
    <v-card
      v-if="paymentBatch !== null"
      class="mb-5"
      style="z-index: 1000"
    >
      <v-card-title>{{ paymentBatch.title }}</v-card-title>
      <v-card-subtitle>{{ paymentBatch.description }}</v-card-subtitle>
      <v-card-actions>
        <v-btn
          :loading="pausing"
          :disabled="pausing"
          depressed
          color="info"
          @click="pauseBatch"
          v-if="paymentBatch.state !== 'paused' && paymentBatch.state !== 'closed'"
        >
          {{ $t('pause') }}
        </v-btn>
        <v-btn
          :loading="resuming"
          :disabled="resuming"
          depressed
          color="info"
          @click="resumeBatch"
          v-if="paymentBatch.state === 'paused'"
        >
          {{ $t('resume') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <ActionList
      :key="paymentBatch.uuid"
      empty-text="No payment batch items"
      :load-items-callback="getPaymentBatchItems"
      :actions="actions"
    >
      <template v-slot:item="{ item, index }">
        <PaymentBatchItem
          :payment-batch-item="item"
          :key="index"
        />
        <v-divider inset />
      </template>
    </ActionList>
  </div>
</template>

<script>

import ActionList from '@/components/common/list/ActionList'
import PaymentBatchItem from '@/components/paymentBatch/PaymentBatchItem'

export default {
	name: 'PaymentBatchProfile',
	props: {
		paymentBatch: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			pausing: false,
			resuming: false
		}
	},
	components: {
		ActionList,
		PaymentBatchItem
	},
	computed: {
		actions() {
			return this.$store.getters.bulkOperationsByRoute('subscriptions#payment-batch-items')
		}
	},
	methods: {
		getPaymentBatchItems() {
			return this.$store.dispatch('getPaymentBatchItems', this.paymentBatch.uuid)
		},
		pauseBatch() {
			this.pausing = true
			this.$store.dispatch('pausePaymentBatch', this.paymentBatch.uuid).finally(() => {
				this.pausing = false
			})
		},
		resumeBatch() {
			this.resuming = true
			this.$store.dispatch('resumePaymentBatch', this.paymentBatch.uuid).finally(() => {
				this.resuming = false
			})
		}
	},
	mounted: {}
}
</script>
